<template>
  <div>
    <div class="bg-ff">
      <div class="container">
        <div class="flexWrapCont pt20 pb20">
          <div class="logo">
            <img src="@/assets/images/common/logo.png" alt="">
          </div>
          <div class="rightWrap">
            <div class="textRight phoneWrap">
              <img src="@/assets/images/common/index_09.png" class="mr9" alt="">
              <span>全国热线：</span>
              <i class="mr25">{{ hot.hotPhone }}</i>
              <i>{{ hot.fourHundred }}</i>
            </div>
            <div class="flexWrap flex-xCenter">
              <div class="nav">
                <ul class="navList">
                  <li>
                    <router-link to="/"  class="link fs16 pb10 f600" :class="{ 'curLink' : $route.name === 'index' }">首页</router-link>
                  </li>
                  <li>
                    <div class="link fs16 pb10 f600" :class="{ 'curLink' : ['introduction', 'honor'].includes($route.name) }">公司简介</div>
                    <div class="second">
                      <ul>
                        <li>
                          <router-link to="/introduction" class="innerLink" :class="{ 'curLink' : $route.name === 'introduction' }">公司简介</router-link>
                        </li>
                        <li>
                          <router-link to="/honor" class="innerLink" :class="{ 'curLink' : $route.name === 'honor' }">企业荣誉</router-link>
                        </li>
                        <li>
                          <router-link to="/company" class="innerLink" :class="{ 'curLink' : $route.name === 'company' }">分子公司</router-link>
                        </li>
                        <li>
                        <router-link to="/research" class="innerLink" :class="{ 'curLink' : $route.name === 'research' }">研究中心</router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="link fs16 pb10 f600" :class="{ 'curLink' : ['cases', 'news'].includes($route.name) }">企业EAP</div>
                    <div class="second">
                      <ul>
                        <li>
                          <router-link to="/news" class="innerLink" :class="{ 'curLink' : $route.name === 'news' }">馨闻动态</router-link>
                        </li>
                        <li v-for="(item) in links" :key="item.id">
                          <router-link :to="`/cases?erp=${item.id}`" class="innerLink" :class="{ 'curLink' : $route.query && $route.query.erp == item.id }">
                            {{ item.categoryName }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="link fs16 pb10 f600" :class="{ 'curLink' : $route.name === 'course' }">心理课程</div>
                    <div class="second">
                      <ul>
                        <li v-for="(item) in links2" :key="item.id">
                          <router-link :to="`/course?cId=${item.id}`" class="innerLink" :class="{ 'curLink' : $route.query && $route.query.cId == item.id }">
                            {{ item.categoryName }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="link fs16 pb10 f600" :class="{ 'curLink' : $route.name === 'consultation' }">心理咨询</div>
                    <div class="second">
                      <ul>
                        <li v-for="(item) in links1" :key="item.id">
                          <router-link :to="`/consultation?sul=${item.id}`" class="innerLink" :class="{ 'curLink' : $route.query && $route.query.sul == item.id }">
                            {{ item.categoryName }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div class="link fs16 pb10 f600" :class="{ 'curLink' : $route.name === 'welfare' }">上馨公益</div>
                    <div class="second">
                      <ul>
                        <li v-for="(item) in links3" :key="item.id">
                          <router-link :to="`/welfare?wel=${item.id}`" class="innerLink" :class="{ 'curLink' : $route.query && $route.query.wel == item.id }">
                            {{ item.categoryName }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <router-link to="/concat" class="link fs16 pb10 f600" :class="{ 'curLink' : $route.name === 'concat' }">联系我们</router-link>
                  </li>
                </ul>
              </div>
              <a href="javascript:;" class="consultation fs14 c-ff textCenter" @click="qrcode = true">免费咨询</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="qrcode" class="qrcodeFixed">
      <img :src="hot.qrcodeUrl" alt=""  class="qrcodeImg" />
      <img src="@/assets/images/common/index-close.png" alt="" class="closeImg" @click="qrcode = false" />
    </div>
  </div>
</template>

<script>
import {consulCategoryList, erpCategoryList, courseCategory, welfareCategoryList} from "@/api"

export default {
  props: {
    hot: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      qrcode: false,
      links3: [],
      links2: [],
      links1: [],
      links: [],
    }
  },
  created() {
    this.getCategoryList()
    this.getCategoryList1()
    this.getCategoryList2()
    this.getCategoryList3()
  },
  methods: {
    async getCategoryList3() {
      const { data } = await welfareCategoryList()
      if (data.code === 0) {
        this.links3 = data.data
      }
    },
    async getCategoryList2() {
      const { data } = await courseCategory()
      if (data.code === 0) {
        this.links2 = data.data
      }
    },
    async getCategoryList1() {
      const { data } = await consulCategoryList()
      if (data.code === 0) {
        this.links1 = data.data
      }
    },
    async getCategoryList() {
      const { data } = await erpCategoryList()
      if (data.code === 0) {
        this.links = data.data
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .qrcodeFixed {
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    width: 361px;
    height: 454px;
    box-sizing: border-box;
    padding: 135px 101px;
    transform: translate(-50%,-50%);
    background: url("~@/assets/images/common/qrcode.png") no-repeat center;
    .qrcodeImg {
      display: block;
      width: 160px;
    }
    .closeImg {
      width: 17px;
      position: absolute;
      bottom: 13px;
      right: 34px;
      cursor: pointer;
    }
  }
  .consultation {
    display: block;
    width: 80px;
    flex: 0 0 80px;
    line-height: 28px;
    border-radius: 20px;
    background: #f39800;
  }
  .flexWrapCont {
    display: flex;
    .rightWrap {
      flex: 1;
    }
  }
  .nav {
    flex: 1;
    position: relative;
    top: 18px;
    padding-right: 120px;
    .navList {
      display: flex;
      justify-content: space-around;
    }
    li {
      //display: inline-block;
      //vertical-align: middle;
      //margin-left: 30px;
      position: relative;
      &:hover {
        .second {
          display: block;
        }
        .link {
          color: #25a8f1;
          &::after {
            opacity: 1;
          }
        }
      }
      .link {
        font-family: "Microsoft Yahei";
        color: #313131;
        display: block;
        position: relative;
        &.curLink {
          color: #25a8f1;
          &::after {
            opacity: 1;
          }
        }
        &::after {
          content: '';
          display: block;
          margin: 18px auto 0;
          width: 32px;
          height: 3px;
          background: #25a8f1;
          border-radius: 3px;
          opacity: 0;
        }
      }
      .second {
        min-width: 180px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        z-index: 999;
        text-align: center;
        box-sizing: border-box;
        padding: 5px 10px;
        border-radius: 3px;
        box-shadow: 0 0 8px 3px rgba(0,0,0, .06);
        display: none;
        .innerLink {
          display: block;
          font-size: 16px;
          color: #313131;
          font-family: "Microsoft Yahei";
          line-height: 43px;
          white-space: nowrap;
          &:hover, &.curLink {
            color: #25a8f1;
          }
        }
        li{
          display: block;
          margin-left: 0;
          &:not(:last-child) {
            border-bottom: 1px dashed #ddd;
          }
        }
      }
    }
  }
  .phoneWrap {
    font-size: 18px;
    color: #25a8f1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: #666;
    }
  }
</style>
